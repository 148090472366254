<template>
  <div class="data-total">
    <div style="position: fixed; top: 10rem; width: 100%; z-index: 999">
      <van-loading type="spinner" vertical color="#1989fa" v-if="loading" />
    </div>
    <van-search
      v-model="searchValue"
      placeholder="筛选提升机"
      @input="searchList"
    />
    <template v-for="(item, index) in list">
      <div class="roblist" :key="index" @click="to(item.id, item.device_name)">
        <p class="title">
          {{ item.device_name }}
          <span>
            <img width="14px" class="now" :src="getStatusImage(item)" />
            {{ item.status }}
          </span>
        </p>
        <p class="subtitle">{{ item.organization_name }}</p>
        <template v-if="item.metrics && item.metrics.length">
          <div v-for="(it, itindex) in item.metrics" :key="itindex">
            <p class="monitor">
              {{ it.name
              }}<span
                >{{ it.value.toFixed(2)
                }}{{ it.unit ? ` (${it.unit})` : "" }}</span
              >
            </p>
          </div>
          <p class="warning monitor">
            故障数量<span>{{ item.event_count }}</span>
          </p>
          <p class="monitor">
            数据更新时间<span
              :style="{
                color: getTimeColor(
                  item.metrics[0].timestamp,
                  item.status === '离线',
                  item.status === '未激活'
                ),
              }"
              >{{
                item.metrics && item.metrics[0]
                  ? moment(item.metrics[0].timestamp).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : "-"
              }}</span
            >
          </p>
        </template>
        <p v-else class="monitor">
          数据更新时间<span style="color: #000">-</span>
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { Overview } from '@/api/index'
import moment from "moment";

export default {
  name: "total",
  data() {
    return {
      loading: false,
      searchValue: '',
      list: []
    };
  },
  created() {
    this.searchList()
    let that = this;
    console.log('111')

    setInterval(() => {
      that.searchList()
    }, 10000)


    // this.loading=true;
    // const data = {
    //   limit: 1000,
    //   offset: 0
    // }
    // Overview(data).then((response) => {
    //   this.loading=false;
    //   console.log(response)
    //   this.list = response.data.results
    // })
  },
  methods: {
    moment,
    to(index, name) {
      this.$router.push({ path: '/content/monitor', query: { id: index, device_name: name } })
    },
    searchList() {
      this.loading = true;
      const data = {
        limit: 1000,
        offset: 0,
        q: this.searchValue
      }
      Overview(data).then((response) => {
        this.loading = false;
        console.log(response)
        this.list = response.data.results
      })
    },
    getStatusImage(device) {
      console.log(device.device_name, device.status, device)
      const metric = device.metrics && device.metrics.length > 0 ? device.metrics[0] : { timestamp: undefined }
      const path = this.getTimePath(metric.timestamp, device.status === '离线', device.status === '未激活')
      return path
    },
    getTimePath(time, offLine = false, deactive = false) {
      if (!time || offLine) return require('@/assets/bigscreen/offline.png')
      if (deactive) return require('@/assets/bigscreen/deactive.png')
      if (this.showError) return require('@/assets/bigscreen/interrup.png')

      const date = new Date(time)
      const now = new Date()
      if (now - date < 1000 * 60) {
        return require('@/assets/bigscreen/online.png')
      } else if (now - date < 1000 * 60 * 60) {
        return require('@/assets/bigscreen/interrup.png')
      } else {
        return require('@/assets/bigscreen/numb.png')
      }
    },
    getTimeColor(time, offLine = false, deactive = false) {
      if (offLine) return '#909399'
      if (deactive) return '#000'
      if (!time || this.showError) return '#e42b2b'

      const date = new Date(time)
      const now = new Date()
      if (now - date < 1000 * 60) {
        return '#409eff'
      } else if (now - date < 1000 * 60 * 60) {
        return '#f3ab3f'
      } else {
        return '#e42b2b'
      }
    },
  }
}
</script>

<style lang="less" scoped>
.data-total {
  background: #f3f3f3;
  padding-bottom: 55px;
  //padding:0 1%;
  .van-search {
    background: #f3f3f3;
  }

  .van-search__content {
    background: #fff;
  }

  .roblist {
    border-bottom: 1px solid #ccc;
    background: #fff;
    box-shadow: 0 1px 5px 0 #f3f3f3;
    border-radius: 4px;
    padding: 0.4rem 1rem;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.4rem;

    p {
      margin: 0.35rem 0;
      font-size: 13px;
      background: #fff;
    }

    .monitor {
      height: 0.8rem;
      line-height: 0.8rem;
      position: relative;
      border-bottom: 0.01rem solid #fefefe;
      padding-bottom: 8px;

      span {
        position: absolute;
        right: 0;
        padding-left: 10px;
      }
    }

    .title {
      font-size: 13px;
      font-weight: 800;

      span {
        float: right;
        display: flex;
        align-items: center;

        b {
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin-right: 2px;
        }
      }
    }

    .subtitle {
      font-size: 13px;
      color: #999;
      margin-bottom: 0.5rem;
    }

    .warning {
      font-size: 12px;
      color: red;
      padding-top: 0.2rem;
      font-weight: 800;
    }
  }

  .roblist:nth-child(2) {
    //border-top: 1px solid #ccc;
  }
}
</style>
